import React from 'react';
import css from './HeroSection.module.css';
import { NamedLink } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import { Carousel } from 'react-responsive-carousel';
import image from '../../../../assets/biketribe-brandImage-1500.jpg';
import imageTwo from '../../../../assets/image2.jpg';
import image3 from '../../../../assets/three.jpg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const HeroSection = () => {
  const images = [image, imageTwo, image3];
  const history = useHistory();
  // const text = (
  //   <div className={css.container}>
  //     <div className={css.HeroSection}>
  //       <h1 className={css.heroMainTitle}>
  //         <FormattedMessage id="HeroSection.HeroSection.title" />
  //       </h1>
  //       <p className={css.heroSubTitle}>
  //         <FormattedMessage id="HeroSection.HeroSection.subTitle" />
  //       </p>

  //       <NamedLink name="SearchPage" className={css.searchButton}>
  //         <FormattedMessage id="HeroSection.HeroSection.buttonTitle" />
  //       </NamedLink>
  //     </div>
  //   </div>
  // );
  return (
    <>
      <Carousel
        autoPlay={true}
        showStatus={false}
        showThumbs={false}
        showArrows={true}
        showIndicators={false}
        infiniteLoop={true}
        swipeable={true}
        className={css.wrapper}
        transitionTime={2000}
        interval={6000}
      >
        {images?.map((image, index) => (
          // <div className={css.wrapper} key={index}>
          <img src={image} alt="Groundz" className={css.image} />

          // </div>
        ))}
      </Carousel>
      <div className={css.container}>
        <div className={css.HeroSection}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="HeroSection.HeroSection.title" />
          </h1>
          <p className={css.heroSubTitle}>
            <FormattedMessage id="HeroSection.HeroSection.subTitle" />
          </p>
          <div className={css.buttonContainer}>
            <div
              onClick={() => history.push('/product?pub_listingType=product-selling')}
              className={css.searchButton}
            >
              <FormattedMessage id="HeroSection.HeroSection.buttonTitle" />
            </div>
            <div
              onClick={() => history.push('/gym?pub_listingType=gym')}
              name="SearchPage"
              className={css.searchButton}
            >
              <FormattedMessage id="HeroSection.HeroSection.buttonTitleplatform" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
